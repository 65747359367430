@import "modern-css-reset/dist/reset.css";
@import "luminous-lightbox/dist/luminous-basic.css";
@import "_custom-media.css";
@import "./menu-btn.css";

:root {
  --slate-50: #f8fafc;
  --slate-100: #f1f5f9;
  --slate-200: #e2e8f0;
  --slate-300: #cbd5e1;
  --slate-400: #94a3b8;
  --slate-500: #64748b;
  --slate-600: #475569;
  --slate-700: #334155;
  --slate-800: #1e293b;
  --slate-900: #0f172a;
  --gray-50: #f9fafb;
  --gray-100: #f3f4f6;
  --gray-200: #e5e7eb;
  --gray-300: #d1d5db;
  --gray-400: #9ca3af;
  --gray-500: #6b7280;
  --gray-600: #4b5563;
  --gray-700: #374151;
  --gray-800: #1f2937;
  --gray-900: #111827;
  --primary-50: #fdf2f8;
  --primary-100: #fce7f3;
  --primary-200: #fbcfe8;
  --primary-300: #f9a8d4;
  --primary-400: #f472b6;
  --primary-500: #ec4899;
  --primary-600: #db2777;
  --primary-700: #be185d;
  --primary-800: #9d174d;
  --primary-900: #831843;
  --secondary-50: #ecfdf5;
  --secondary-100: #d1fae5;
  --secondary-200: #a7f3d0;
  --secondary-300: #6ee7b7;
  --secondary-400: #34d399;
  --secondary-500: #10b981;
  --secondary-600: #059669;
  --secondary-700: #047857;
  --secondary-800: #065f46;
  --secondary-900: #064e3b;

  /* --primary: #f2879b; */
  --primary: var(--primary-500);
  --secondary: var(--secondary-500);
  --color-mercari: #ff0211;

  /* #f2879b */
  --thirty: #c7d9ad;
  --accent: #d9aa8f;
  --text-3xl: 2rem;
  --text-2xl: 1.5rem;
  --text-xl: 1.25rem;
  --text-lg: 1.125rem;
  --text-lg-clamp: clamp(1rem, 0.8645rem + 0.6024vw, 1.25rem);
  --text-sm: 0.875rem;
  --text-xs: 0.75rem;
  --text-light: var(--gray-500);
  --text: var(--gray-700);
  --font-size-h1: clamp(1.5rem, 0.75rem + 1.875vw, 2.25rem);
  --font-size-h2: clamp(1.25rem, 0.9789rem + 1.2048vw, 1.75rem);
  --font-size-h3: clamp(1.1875rem, 1.1536rem + 0.1506vw, 1.25rem);
  --font-family-maru: "dnp-shuei-mgothic-std", sans-serif;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: var(--font-family-maru);
  line-height: 1.75;
  color: #27272a;
  background-color: white;
  transition: opacity 1s;
}

.show-lg {
  display: none;
}

.tel {
  color: inherit;
  text-decoration: none;
}

h3 {
  font-size: var(--font-size-h3);
}

img {
  max-width: 100%;
}

p > a {
  color: var(--primary);
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.alert {
  padding: 1rem 1.5rem;
  background-color: var(--gray-100);
  border-radius: 0.5rem;
}

.column-2 {
  display: grid;
  gap: 3rem;
}

.container-xs,
.container-sm,
.container-md,
.container {
  margin-right: auto;
  margin-left: auto;
}

.container-xs {
  max-width: 40rem;
}

.container-sm {
  max-width: 52.5rem;
}

.contact-btn {
  display: grid;
  place-items: center;
  width: 6rem;
  text-decoration: none;
}

.contact-btn-inner {
  display: grid;
  place-items: center;
  height: 3rem;
}

.contact-btn-mail {
  color: var(--secondary);
  color: white;
  background-color: var(--secondary);
}

.contact-btn-phone {
  color: white;
  background-color: var(--primary-500);

  /* background-color: var(--thirty); */
}

.contact-btn-icon {
  display: block;
  width: 1.5rem;
  height: 1.5rem;
}

.contact-btn-text {
  height: auto;
}

.contact-btn-wrapper {
  display: flex;
}

.copyright {
  margin-top: 3rem;

  & > small {
    font-size: var(--text-xs);
  }
}

.cover {
  max-width: 80svw;
  margin: 3rem auto;
}

.customer-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: clamp(1rem, 0.4578rem + 2.4096vw, 2rem);
}

.customer-list-item {
  display: grid;
  gap: 1rem;
  justify-items: center;
  padding: 1rem;
  font-size: clamp(0.875rem, 0.8072rem + 0.3012vw, 1rem);
  color: var(--primary-600);
  text-align: center;
  background-color: var(--gray-100);
  border: 2px dashed var(--primary-500);
  border-color: transparent;
  border-radius: 0.5rem;
}

.customer-list-icon {
  width: 6rem;
  height: 6rem;
  overflow: hidden;
  text-align: center;
  background-color: white;
  border-radius: 9999px;

  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.equipment-h3 {
  width: fit-content;
  padding: 0.25rem 1.5rem;
  margin: 0.5rem auto 1rem;
  font-size: 1rem;
  text-align: center;
}

.faq {
  & > * + * {
    margin-top: 0.25rem;
  }
}

.faq-content {
  position: relative;
  display: flex;
  gap: 1rem;
  padding: 0.75rem 1rem;
}

.faq-content p {
  margin-top: 0.5rem;
}

.faq-content::before {
  display: block;
  flex-shrink: 0;
  width: 2.5rem;
  height: 2.5rem;
  content: "";
  background-image: url("../images/a.webp");
  background-size: contain;
}

.faq details {
  background-color: var(--primary-50);
}

.faq details[open] summary::after {
  transform: rotateX(180deg);
}

.faq summary {
  display: flex;
  gap: 1rem;
  align-items: center;
  padding: 0.75rem 1rem;
  cursor: pointer;
}

.faq summary::before {
  display: grid;
  flex-shrink: 0;
  width: 2.5rem;
  height: 2.5rem;
  content: "";
  background-image: url("../images/q.webp");
  background-size: contain;
}

.faq summary::after {
  width: 1.5rem;
  height: 1.5rem;
  margin-left: auto;
  content: "";
  background-color: var(--text-light);
  transition: transform 0.3s ease;
  mask-image: url("../svg/chevron-down.svg");
  mask-repeat: no-repeat;
}

.faq summary::-webkit-details-marker {
  display: none; /* 矢印を消す Chrome, Safari */
}

.footer {
  padding: clamp(1rem, -0.0843rem + 4.8193vw, 3rem);
  font-size: clamp(0.875rem, 0.8072rem + 0.3012vw, 1rem);
  text-align: center;
  background-color: var(--primary-100);
}

.footer-logo {
  /* text-align: center; */

  & img {
    width: 14rem;
    margin-right: auto;
    margin-bottom: 2rem;
    margin-left: auto;

    /* filter: grayscale(1) opacity(0.5); */
  }
}

.footer-nav {
  display: flex;
  gap: 2rem;
  justify-content: center;
  padding-left: 0;
  list-style: none;

  & a {
    color: inherit;
    text-decoration: none;
  }

  & a:hover {
    color: var(--primary);
  }
}

.h2-1 {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  width: fit-content;

  &::before,
  &::after {
    display: block;
    width: 2.5rem;
    height: 2.5rem;
    content: url("../svg/h2.svg");
  }

  &::before {
    left: 0;
  }

  &::after {
    right: 0;
    transform: scaleX(-1);
  }
}

/* .hero {
  position: relative;
  overflow: hidden;
}

.hero-figure {
  top: 0;
  right: 0;
  width: 170svw;
  height: calc(100svh - 5rem);
  margin-left: -70svw;
  overflow: hidden;
}

.hero-figure > img {
  width: 100%;
  height: 100%;
  filter: contrast(110%) saturate(120%);
  animation: 2s ease fadein;
  object-fit: cover;
  object-position: center;
} */

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.hero > h2 {
  position: absolute;
  top: 20%;
  right: 0;
  left: 0;
  display: grid;
  place-items: center;
  font-family: "Noto Serif JP", serif;
  font-size: clamp(1.5rem, 1.2289rem + 1.2048vw, 2rem);
  font-weight: 600;
  color: white;
  text-align: center;

  /* text-shadow: 0 0.25rem 2rem  rgb(219 39 119 / 100%); */
}

.highlight {
  display: inline;
  background-image: linear-gradient(to bottom, transparent 55%, var(--primary-200) 55%);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

/* .highlight {
  position: relative;
  font-weight: bold;

  & > span {
    position: relative;
    z-index: 2;
  }

  &::after {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    display: block;
    width: 100%;
    height: 0.5rem;
    content: "";
    background-color: var(--primary-400);
    opacity: 0.3;
  }
} */

.icon-mame {
  display: block;
  width: 6rem;
  margin-right: auto;
  margin-bottom: 3rem;
  margin-left: auto;
}

.label {
  padding: 0.5rem 1rem;
  background-color: var(--gray-200);
  border-radius: 0.5rem;
}

.lightbox-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: clamp(1rem, 0.4578rem + 2.4096vw, 2rem);
  align-items: center;

  /* margin-top: 4rem;
  margin-bottom: 4rem; */
}

.lightbox-thumbnail {
  overflow: hidden;
  cursor: zoom-in;
  background-color: var(--secondary);
  border: 4px solid white;
  border-radius: 0.25rem;

  & img {
    aspect-ratio: 4 / 3;
    object-fit: cover;
    transition: 0.3s;
  }

  & img:hover {
    opacity: 0.7;
  }
}

.list {
  padding-left: 1.25rem;
}

.list-no-style {
  padding-left: 0;
  list-style: none;
}

.list-horizontal {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem 2rem;
  justify-content: center;
  list-style-position: inside;

  & li {
    padding-left: 1rem;
  }

  &::marker {
    margin-right: 0.25rem;
  }
}

.list-col2,
.list-col3 {
  display: grid;
  gap: 0.5rem 2rem;

  & > li {
    padding-left: 1rem;
  }
}

.list-inside {
  list-style-position: inside;
}

.logo img {
  width: clamp(14rem, 12.9157rem + 4.8193vw, 16rem);
}

.message-title {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;

  & > span:first-child {
    flex-basis: 100%;
  }

  & > img {
    width: clamp(14rem, 9.6627rem + 19.2771vw, 22rem);
    margin-top: -0.375rem;
    transform: translateY(-0.3rem);
  }

  /* color: #16377f; */
}

.mt {
  margin-top: 1.5rem;
}

.mt-lg {
  margin-top: 3rem;
}

.mt-xl {
  margin-top: 4.5rem !important;
}

.nav {
  display: flex;
  align-items: center;
  align-items: stretch;
  justify-content: space-between;
  height: clamp(5rem, 4.4578rem + 2.4096vw, 6rem);
  padding-left: 1.5rem;
}

.nav-brand {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.nav-description {
  display: none;
}

.nav-phone-wrapper {
  display: none;
  margin-right: 1.5rem;
}

.nav-phone,
.footer-phone {
  display: flex;
  gap: 0.25rem;
  align-items: center;
  color: var(--primary);
}

.nav-phone-icon,
.footer-phone-icon {
  width: 1.5rem;
  height: 1.5rem;
}

.nav-phone-number,
.footer-phone-number {
  font-size: 1.75rem;
  font-weight: bold;
  line-height: 1.25;
}

.footer-phone-wrapper {
  display: grid;
  gap: 1rem;
  justify-items: center;
}

.footer-phone-info {
  font-size: var(--text-sm);
}

.footer-phone-sub {
  display: flex;
  gap: 0.25rem;
  justify-content: center;
  font-size: var(--text-lg);

  /* color: #0ea5e9; */
}

.footer-phone-sub-icon {
  width: 1rem;
}

.phone-sub {
  display: flex;
  gap: 0.25rem;
  justify-content: center;

  /* align-items: center; */
  font-size: var(--text-2xl);
  color: #0ea5e9;
}

.phone-sub-icon {
  width: 1.25rem;
}

.price {
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--primary);
}

.price-sm {
  font-weight: bold;
  color: var(--primary);
}

.price-label {
  font-weight: bold;
}

.price-category {
  /* position: relative; */
  padding: 0 1rem 1rem;
  margin-top: 3rem;
  background-color: white;
  border: 3px dotted var(--primary);
  border-radius: 0.75rem;

  & > h3 {
    width: fit-content;
    padding: 0.375rem 1rem;
    font-size: 1rem;
    color: white;
    background-color: var(--primary);
    border-radius: 0.75rem;
    transform: translateY(-1.5rem);
  }
}

.price-ticket {
  position: relative;
  padding: 1.5rem;
  margin-top: 6rem !important;
  background-color: white;

  /* border: 1px solid var(--secondary-200); */
  border: transparent;
  border-radius: 1rem;

  & > h3 {
    color: var(--secondary);
  }
}

.price-ticket-icon {
  position: absolute;
  top: -5rem;
  right: 4rem;
  width: 7rem;
  transform: rotate(-10deg);
}

.reserve {
  position: relative;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: clamp(2rem, 1.4578rem + 2.4096vw, 3rem);
    aspect-ratio: 1 / 1;

    /* 右三角形 */

    clip-path: polygon(0 0, 0 100%, 100% 0%);
    content: "";
    background-color: var(--primary);
  }
}

.reserve-heading {
  margin-top: 4rem !important;
  margin-bottom: 2rem !important;
  font-weight: bold;
}

.reserve-phone-hero {
  display: grid;
  place-items: center;
}

.reserve-phone-wrapper {
  gap: 0.5rem;
  justify-items: center;
  padding: 3rem 4rem;
  text-align: center;
  background-color: var(--gray-100);
  border-radius: 1rem;
}

.reserve-phone {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
}

.reserve-phone-number {
  font-size: clamp(1.5rem, 1.012rem + 2.1687vw, 2.4rem);
  font-weight: bold;
  color: var(--primary);
}

.reserve-phone-icon {
  width: clamp(1.5rem, 1.012rem + 2.1687vw, 2.4rem);
  color: var(--primary);
}

.reserve-content {
  margin-top: 3rem;
}

.section {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.section:not(.section-message):nth-child(odd) {
  background-color: var(--gray-100);
}

.section h2:first-child {
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-right: auto;
  margin-bottom: 2rem;
  margin-left: auto;
  font-size: var(--font-size-h2);
  text-align: center;
}

/* Scene */
.icons {
  display: flex;
  gap: 2rem;
  justify-content: center;

  & img {
    width: 5rem;
    height: 5rem;

    /* border: 4px solid #eee; */
    border-radius: 999px;
  }
}

.scene-icons {
  margin-top: 3rem;
}

.scene-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: clamp(1rem, 0.4578rem + 2.4096vw, 2rem);
  padding-left: 0;
  list-style: none;
}

.scene-list li {
  padding: 1rem 2rem;
  color: var(--secondary-600);

  /* font-size: var(--text-lg-clamp); */
  background-color: var(--gray-200);
  background-color: white;
  border: 2px dotted var(--secondary);
  border-radius: 0.5rem;
}

.section-message {
  background-color: var(--primary-100);
  background-image: linear-gradient(
    90deg,
    rgb(255 219 216 / 100%),
    rgb(255 246 243 / 100%) 50%,
    rgb(255 231 216 / 100%)
  );
}

.section-shop {
  position: relative;
}

.shikaku {
  color: #0ea5e9;
}

.side-gutter {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}

.shop-icon {
  display: block;
  width: 7rem;
  margin-right: auto;
  margin-left: auto;
}

.shop-icon-pc {
  display: none;
}

.shop-item {
  display: grid;
  gap: 2rem;
  justify-items: center;
}

.shop-products {
  width: 36rem;
}

.shop-mercari {
  width: 16rem;
}

.shop-btn {
  display: inline-flex;
  gap: 1rem;
  align-items: center;
  padding: 0.57rem 2rem;
  font-weight: bold;
  color: var(--color-mercari);
  text-decoration: none;
  background-color: white;
  border-radius: 999px;
  transition: 0.3s;

  & > svg {
    width: 2.4rem;
    transition: 0.3s;

    & > .cls-1 {
      fill: none;
      stroke: #ff0211;
      stroke-miterlimit: 10;
      stroke-width: 2px;
      transition: 0.3s;
    }

    & > .cls-2 {
      fill: #ff0211;
      stroke-width: 0;
      transition: 0.3s;
    }
  }
}

.shop-btn:hover {
  color: white;
  background-color: var(--color-mercari);
}

.shop-btn:hover > svg {
  /* transform: scale(1.2); */

  & > .cls-1 {
    stroke: white;
  }

  & > .cls-2 {
    fill: white;
  }
}

.shop-mamechan {
  display: block;
  width: clamp(16rem, 14.4348rem + 6.9565vw, 20rem);
  margin: 2rem auto;
}

.shop-yajirushi {
  display: none;
}

.space-y {
  & > * + * {
    margin-top: 1.5rem;
  }

  & > h3:not(:first-child) {
    margin-top: 3rem;
  }
}

.table-row {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  align-items: center;
  justify-content: space-between;
  padding: 0.25rem 1rem;
}

.table-head {
}

.table-data {
  margin-left: auto;
  font-weight: bold;
  color: var(--primary);
}

.table-data > span {
  font-size: var(--text-sm);
  font-weight: normal;
  color: initial;
}

.text-light {
  color: var(--text-light);
}

.text-sm {
  font-size: var(--text-sm);
}

.text-xs {
  font-size: var(--text-xs);
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-h2 {
  font-size: var(--font-size-h2);
}

.whitespace-nowrap {
  white-space: nowrap;
}

.yajirusi {
  display: block;
  width: 2.5rem;
  margin: 1rem auto;
  color: var(--primary);
}

.lum-lightbox {
  z-index: 100;
  background-color: rgb(0 0 0 /80%);
}

/* .lum-lightbox-inner img {
  width: 100%;
} */

.lum-lightbox-image-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.lum-img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

@media (--sm-max) {
  .section-message > p {
    font-size: var(--text-sm);
  }

  .price-ticket-icon {
    display: none;
  }

  .scene-list {
    grid-template-columns: repeat(1, 1fr);
  }

  .phone-sub {
    font-size: var(--text-lg);
    color: #0ea5e9;
  }

  .phone-sub-icon {
    width: 1rem;
  }
}

@media (--sm) {
  .show-sm {
    display: none;
  }

  .show-lg {
    display: inline-block;
  }

  .hero-figure {
    width: 130svw;
    margin-left: -30svw;
  }

  .nav-description {
    display: block;
  }

  .message-title {
    align-items: center;

    & > span:first-child {
      flex-basis: auto;
    }

    & > img {
      margin-top: 0;
      transform: translateY(-0.82rem);
    }

    /* color: #16377f; */
  }
}

@media (--md) {
  .cover {
    max-width: 60svw;
  }

  .faq details {
    border-radius: 0.5rem;
  }

  .hero-figure {
    width: 110svw;
    margin-left: -10svw;
  }
}

@media (--lg-max) {
  .column-2 > .price-category {
    margin-top: 0;
  }
}

@media (--lg) {
  .contact-btn-phone {
    display: none;
  }

  .h2-1 {
    gap: 2rem;
  }

  .column-2 {
    grid-template-columns: 1fr 1fr;
  }

  .container-md {
    max-width: 80rem;
  }

  .customer-list {
    grid-template-columns: repeat(4, 1fr);
  }

  .faq {
    & > * + * {
      margin-top: 0.75rem;
    }
  }

  .hero-figure {
    width: 100svw;
    margin-left: 0;
  }

  .list-col2 {
    grid-template-columns: repeat(2, 1fr);
  }

  .list-col3 {
    grid-template-columns: repeat(3, 1fr);
  }

  .nav-phone-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .reserve-phone-wrapper {
    padding-right: 6rem;
    padding-left: 6rem;
    background-color: var(--gray-100);
  }

  .icons,
  .scene-icons {
    gap: 4rem;

    & img {
      width: 100px;
      height: 100px;
    }
  }
}

@media (--xxl) {
  html {
    font-size: 18px;
  }

  .shop-icon-phone {
    display: none;
  }

  .shop-icon-pc {
    display: block;
  }

  .shop-mamechan {
    position: absolute;
    right: 5%;
    bottom: 15rem;
    width: auto;
  }

  .shop-mamechan-icon {
    width: 20svw;
  }

  .shop-yajirushi {
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 8svw;
  }
}

@media (--xxxl) {
  html {
    font-size: 20px;
  }
}
