/* .menu-btn {
  display: none;
} */

.menu-btn {
  --btn-size: 4rem;
  --btn-color: #333;

  position: relative;
  z-index: 10;
  display: flex;
  flex-direction: column;
  gap: 0.375em;
  align-items: center;
  justify-content: center;
  width: var(--btn-size);
  height: var(--btn-size);
  font-size: 1rem;
  background-color: transparent;
  border: none;
}

.menu-btn-bar {
  width: 2em;
  height: 0.1875em;
  background-color: var(--btn-color);
  transition: all 0.3s ease;
}

.menu-open {
  & .menu-btn-bar:first-child {
    transform: translateY(0.5625em) rotate(135deg);
  }

  & .menu-btn-bar:nth-child(2) {
    width: 0;
  }

  & .menu-btn-bar:nth-child(3) {
    transform: translateY(-0.5625em) rotate(-135deg);
  }

  & .menu-btn-bar {
    background-color: var(--btn-color);
  }
}
